/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';

export default function Model({ ...props }) {
	const group = useRef();
	const artifact = useRef();

	useFrame(() => {
		if (artifact.current) {
			artifact.current.rotation.x += 0.0021;
			artifact.current.rotation.y += 0.001;
			artifact.current.rotation.z += 0.0013;
		}
	});

	const { nodes, materials } = useGLTF(props.file);
	return (
		<group ref={group} {...props} dispose={null}>
			<mesh
				geometry={nodes.Cone.geometry}
				material={nodes.Cone.material}
				position={[-3.1, -0.2, -3.1]}
				rotation={[0, -Math.PI / 4, 0]}
				scale={[3.1, 3.1, 3.1]}
			/>
			<mesh
				geometry={nodes.Cone001.geometry}
				material={nodes.Cone001.material}
				position={[3.1, -0.2, -3.1]}
				rotation={[0, -Math.PI / 4, 0]}
				scale={[3.1, 3.1, 3.1]}
			/>
			<mesh
				geometry={nodes.Cone002.geometry}
				material={nodes.Cone002.material}
				position={[3.1, -0.2, 3.1]}
				rotation={[0, -Math.PI / 4, 0]}
				scale={[3.1, 3.1, 3.1]}
			/>
			<mesh
				geometry={nodes.Cone003.geometry}
				material={nodes.Cone003.material}
				position={[-3.1, -0.2, 3.1]}
				rotation={[0, -Math.PI / 4, 0]}
				scale={[3.1, 3.1, 3.1]}
			/>
			<mesh
				ref={artifact}
				geometry={nodes.Artifact.geometry}
				material={nodes.Artifact.material}
				position={[0, 1.85, 0.02]}
				scale={3.1}
			/>
			<mesh
				geometry={nodes.Altar.geometry}
				material={nodes.Altar.material}
				position={[0, -0.2, 0]}
				scale={3.1}
			/>
			<mesh
				geometry={nodes.Ground.geometry}
				material={nodes.Ground.material}
				position={[0, 0.1, 0]}
				scale={14.88}
			/>
			<mesh
				geometry={nodes.rock.geometry}
				material={nodes.rock.material}
				position={[-0.9, -0.06, -9.57]}
			/>
			<mesh
				geometry={nodes.rock001.geometry}
				material={nodes.rock001.material}
				position={[1.97, 0.13, 1.11]}
				rotation={[1.37, -0.44, -0.65]}
			/>
			<mesh
				geometry={nodes.rock004.geometry}
				material={nodes.rock004.material}
				position={[2.08, 0.08, 0.31]}
				rotation={[0.01, -0.94, 1.86]}
				scale={0.7}
			/>
			<mesh
				geometry={nodes.rock002.geometry}
				material={nodes.rock002.material}
				position={[-1.17, 0.28, 2.03]}
			/>
			<mesh
				geometry={nodes.rock006.geometry}
				material={nodes.rock006.material}
				position={[2.04, 0, -1.63]}
				rotation={[1.5, 0.23, -3.12]}
				scale={0.81}
			/>
			<mesh
				geometry={nodes.rock008.geometry}
				material={nodes.rock008.material}
				position={[-9.48, -0.09, -2.51]}
			/>
			<mesh
				geometry={nodes.rock009.geometry}
				material={nodes.rock009.material}
				position={[-8.44, -0.68, -2.8]}
				rotation={[-0.74, -0.48, 1.13]}
			/>
			<mesh
				geometry={nodes.rock011.geometry}
				material={nodes.rock011.material}
				position={[-0.66, 0.06, -1.82]}
				rotation={[-2.3, -0.62, 2.48]}
				scale={0.81}
			/>
			<mesh
				geometry={nodes.rock003.geometry}
				material={nodes.rock003.material}
				position={[5.83, -0.17, 8.72]}
			/>
			<mesh
				geometry={nodes.rock005.geometry}
				material={nodes.rock005.material}
				position={[6.8, 0.09, 5.77]}
				rotation={[-0.2, -0.02, 1.08]}
				scale={1.57}
			/>
			<mesh
				geometry={nodes.rock007.geometry}
				material={nodes.rock007.material}
				position={[-2.76, 0.69, 9.73]}
				rotation={[-2.31, -0.78, -1.99]}
				scale={3.02}
			/>
			<mesh
				geometry={nodes.rock010.geometry}
				material={nodes.rock010.material}
				position={[7.23, -0.19, -7.4]}
				rotation={[-0.76, -1.42, -3.04]}
				scale={2.09}
			/>
			<mesh
				geometry={nodes.rock012.geometry}
				material={nodes.rock012.material}
				position={[-1.26, 0, -1.92]}
				rotation={[-1.81, 0.12, 1.27]}
				scale={0.81}
			/>
			<mesh
				geometry={nodes.rock013.geometry}
				material={nodes.rock013.material}
				position={[-2.07, 0.06, 1.1]}
				rotation={[-2.31, 1.25, -2.47]}
				scale={0.57}
			/>
			<mesh
				geometry={nodes.rock014.geometry}
				material={nodes.rock014.material}
				position={[-2.23, -0.09, -1.43]}
				rotation={[1.97, 0.15, -1.17]}
				scale={[1.96, 1.96, 1.96]}
			/>
			<mesh
				geometry={nodes.rock015.geometry}
				material={nodes.rock015.material}
				position={[1.22, -0.06, 1.95]}
				rotation={[-1.34, -0.05, -1.63]}
			/>
			<mesh
				geometry={nodes.rock016.geometry}
				material={nodes.rock016.material}
				position={[0.56, -0.15, 2.1]}
				rotation={[-1.34, -0.05, -1.63]}
				scale={[0.44, 0.44, 0.44]}
			/>
			<mesh
				geometry={nodes.rock017.geometry}
				material={nodes.rock017.material}
				position={[-3.91, 0.57, 8.24]}
				rotation={[2.92, 0.09, -2.96]}
				scale={1.68}
			/>
			<mesh
				geometry={nodes.rock018.geometry}
				material={nodes.rock018.material}
				position={[6.95, -0.07, -6.37]}
				rotation={[-1.72, 0.28, 0.94]}
				scale={1.04}
			/>
			<mesh
				geometry={nodes.rock019.geometry}
				material={nodes.rock019.material}
				position={[8.38, -0.21, -5.81]}
				rotation={[2.69, -0.47, 0.77]}
				scale={1.04}
			/>
			<mesh
				geometry={nodes.rock020.geometry}
				material={nodes.rock020.material}
				position={[7.53, -0.22, 4.99]}
				rotation={[-2.62, -1.15, -1.57]}
			/>
			<mesh
				geometry={nodes.rock021.geometry}
				material={nodes.rock021.material}
				position={[-5.55, -0.41, -6.61]}
				rotation={[2.37, -1.19, 0.2]}
				scale={2.02}
			/>
			<mesh
				geometry={nodes.rock022.geometry}
				material={nodes.rock022.material}
				position={[-6.28, -0.07, -5.58]}
				rotation={[-1.53, -0.7, -1.81]}
				scale={[1.13, 1.13, 1.13]}
			/>
			<mesh
				geometry={nodes.rock023.geometry}
				material={nodes.rock023.material}
				position={[-5.47, -0.06, -5.28]}
				rotation={[1.03, 0.99, -3.1]}
			/>
			<mesh
				geometry={nodes.rock024.geometry}
				material={nodes.rock024.material}
				position={[-4.44, -0.09, -3.97]}
				rotation={[1.62, 0.42, -0.06]}
				scale={[1.13, 1.13, 1.13]}
			/>
			<mesh
				geometry={nodes.rock025.geometry}
				material={nodes.rock025.material}
				position={[-4.38, 0.02, 3.41]}
				rotation={[-0.3, -1.33, 0.66]}
				scale={1.01}
			/>
			<mesh
				geometry={nodes.rock026.geometry}
				material={nodes.rock026.material}
				position={[-4.38, 0.02, 2.8]}
				rotation={[-2.36, 0.61, -1.77]}
				scale={0.67}
			/>
			<mesh
				geometry={nodes.rock027.geometry}
				material={nodes.rock027.material}
				position={[-5.42, -0.01, 3.1]}
				rotation={[0.63, -0.4, -1.33]}
				scale={[0.52, 0.52, 0.52]}
			/>
			<mesh
				geometry={nodes.rock028.geometry}
				material={nodes.rock028.material}
				position={[3.7, 0.14, 4.36]}
				rotation={[-0.2, -0.02, 1.08]}
				scale={0.87}
			/>
		</group>
	);
}

useGLTF.preload('/Sigil2_baked.glb');
