import './styles.css';

import { VRCanvas, DefaultXRControllers, RayGrab } from '@react-three/xr';

import VRtifact from './VRtifact.js';
import { Suspense, useEffect, useState, useRef } from 'react';

import TeleportTravel from './Teleport';
import { useFrame } from '@react-three/fiber';

function Cube(props) {
	return (
		<mesh {...props}>
			<boxBufferGeometry args={[1, 1, 1]} attach="geometry" />
			<meshStandardMaterial attach="material" />
		</mesh>
	);
}

function OminousOrb(props) {
	const { color = 0xff0088 } = props;
	const light = useRef();

	useFrame((state, delta) => {
		if (light.current) {
			light.current.intensity = 0.05 + (1 + Math.sin(performance.now() / 1000));
		}
	});

	return (
		<RayGrab>
			<group {...props}>
				<pointLight ref={light} color={color} />
				<mesh>
					<sphereBufferGeometry args={[0.1, 32, 16]} attach="geometry" />
					<meshBasicMaterial attach="material" color={color} />
				</mesh>
			</group>
		</RayGrab>
	);
}

function Soundtrack() {
	const [file, setFile] = useState();

	useEffect(() => {
		fetch(
			'https://api.opensea.io/api/v1/asset/0x495f947276749ce646f68ac8c248420045cb7b5e/49139736909389367929978572099835670710667289180223653736348200868712567275521/',
			{},
		)
			.then((response) => response.json())
			.then((response) => {
				setFile(response.animation_url);
			})
			.catch((err) => console.error(err));
	}, []);

	return (
		<>
			{file && (
				<audio loop={true} autoPlay={true}>
					<source src={file} type="audio/mp3" />
					Your browser does not support the audio element.
				</audio>
			)}
		</>
	);
}

export default function App() {
	const [gltf, setGltf] = useState();

	useEffect(() => {
		fetch(
			'https://api.opensea.io/api/v1/asset/0x495f947276749ce646f68ac8c248420045cb7b5e/49139736909389367929978572099835670710667289180223653736348200867613055647745/',
			{},
		)
			.then((response) => response.json())
			.then((response) => {
				setGltf(response.animation_url);
			})
			.catch((err) => console.error(err));
	}, []);

	return (
		<>
			<Soundtrack />
			<VRCanvas>
				<directionalLight color={0xff4499} />
				<OminousOrb position={[0, 1.5, -5]} color={0x44ffff} />
				<TeleportTravel>
					{gltf && (
						<Suspense fallback={() => <Cube position={[0, 0.5, -2]} />}>
							<VRtifact file={gltf} position={[0, 0, -5]} />
						</Suspense>
					)}
				</TeleportTravel>
				<DefaultXRControllers />
			</VRCanvas>
		</>
	);
}
